@import '../../styles/variables.scss';

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  font-weight: 600;
  line-height: 16px;
  color: #fff;

  &--small {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    font-size: 10px;
  }

  &--medium {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    font-size: 12px;
  }

  &--large {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    font-size: 14px;
  }
}