@import '../../styles/variables.scss';

.header-section {
  color: $white;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 56px 0;

  h1 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  h6 {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  p {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;

    @media (min-width: $screen-lg) {
      max-width: 33.3%;
    }
  }

  & button {
    &:hover {
      background-color: transparent;
      color: $main-super-dark;
    }
  }
}

.header-section__prompts-good-practices {
  color: $white;
  opacity: 0.8;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: $black-400;
  }

  & h2 {
    font-size: 16px;
  }

  & strong,
  & span {
    font-size: 14px;
  }
}

.header-section__prompts-good-practices__list {
  padding: 16px 32px;

  & > li {
    list-style: disc;

    &:not(:first-child) {
      margin-top: 12px;
    }
  }

  & strong {
    font-weight: 700;
  }
}

.header-section__prompts-good-practices__sub-list {
  padding: 0px 16px;

  & > li {
    list-style: circle;
  }
}
