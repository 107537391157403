// @keyframes grain {

//   0%,
//   100% {
//     transform: translate(0, 0)
//   }

//   10% {
//     transform: translate(-5%, -10%)
//   }

//   20% {
//     transform: translate(-15%, 5%)
//   }

//   30% {
//     transform: translate(7%, -20%)
//   }

//   40% {
//     transform: translate(-5%, 20%)
//   }

//   50% {
//     transform: translate(-15%, 10%)
//   }

//   60% {
//     transform: translate(15%, 0%)
//   }

//   70% {
//     transform: translate(0%, 15%)
//   }

//   80% {
//     transform: translate(3%, 25%)
//   }

//   90% {
//     transform: translate(-10%, 10%)
//   }
// }

@keyframes fly {
  0% {
    opacity: 0;
    transform: rotate(0) translate(200px) rotate(0) scale(1);
  }

  20%,
  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(2turn) translate(300px) scale(.8) rotate(-2turn) scale(2.5);
  }
}

@keyframes flyPlus {
  0% {
    opacity: 0;
    transform: rotate(0) translate(300px) rotate(0);
  }

  40%,
  90% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(-2turn) translate(200px) rotate(2turn) scale(.5);
  }
}

@keyframes mouseAnimation {

  0%,
  50%,
  100% {
    opacity: .5;
  }

  20%,
  90% {
    opacity: 1;
  }

}

.animation-bg {
  z-index: 0;
  opacity: .2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  word-break: break-all;
  overflow: hidden;
  font-family: monospace;
  font-size: 0.85rem;
  line-height: 1.15;
  color: #141414;
  // &::after {
  //   content: "";
  //   display: block;
  //   background: url("https://grainy-gradients.vercel.app/noise.svg");
  //   background-repeat: repeat;
  //   // filter: contrast(170%) brightness(1000%);
  //   width: 300%;
  //   height: 300%;
  //   position: absolute;
  //   top: -100%;
  //   left: -50%;
  //   opacity: .2;
  //   animation: grain 8s steps(10) infinite;
  //   transition: none;
  // }
  pointer-events: none;

  >* {
    pointer-events: none;
  }
}

.animation-bg__bubble {
  display: block;
  aspect-ratio: 1/1;
  border-radius: 100%;
  width: 1500px;
  height: 1500px;
  background: radial-gradient(rgb(23, 24, 37) 20%, #64e7fe 50%, #64feda, rgb(249, 38, 114));
  mix-blend-mode: soft-light;
  filter: blur(50px);
  opacity: 0.8;
  -webkit-filter: blur(50px);
  background-clip: content-box;
  animation: fly 12s linear infinite;
  animation-direction: alternate;
  position: absolute;
  top: 20%;
  left: 0;
}

.animation-bg__bubble2 {
  position: absolute;
  top: 30%;
  left: 20%;
  display: block;
  aspect-ratio: 1/1;
  border-radius: 100%;
  width: 350px;
  height: 350px;
  transform: rotate(0) translate(300px) rotate(0);
  animation: flyPlus 15s linear infinite;
  background: #94fbe3;
  mix-blend-mode: soft-light;
  filter: blur(50px);
  opacity: 0.9;
  -webkit-filter: blur(50px);
  transition: all ease 1s;

  &:nth-of-type(3) {
    background: black;
    animation-duration: 20s;
    left: -50%;
    top: -50%;
    width: 200vh;
    height: 200vh;
    opacity: .5;
  }

  &:nth-of-type(2) {
    background: #64feda;
    animation-duration: 18s;
    left: 0;
    mix-blend-mode: overlay;
  }
}

.animation-bg__mouse {
  position: absolute;
  left: calc(-50vw - 175px);
  top: -175px;
  z-index: 99;
  transition: all 1s cubic-bezier(0.18, 0.89, 0.32, 1.28);
  display: block;
  aspect-ratio: 1/1;
  border-radius: 100%;
  width: 350px;
  height: 350px;
  background: black;
  filter: blur(50px);
  -webkit-filter: blur(50px);
  animation: mouseAnimation 5s linear infinite;
}

.animation-bg__random-text {
  color: rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 150%;
  letter-spacing: 14px;
  padding-left: 6px;
}