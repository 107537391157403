@import '../../styles/variables.scss';

@keyframes loading {
    0% {
        color: $white;
      border: 1px solid $white;
    }
    50% {
        color: $black-500;
      border: 1px solid $black-500;
    }
    100% {
        color: $white;
      border: 1px solid $white;
    }
  }


.document__textarea{
    width: 100%;
    max-width: 1120px;
    height: 462px;
    resize: none;
    border-color: white;
    background-color: transparent;
    padding: 30px;
    box-sizing: border-box;
    font-family: $font-primary;
    font-weight: 400;
    color: $white;
    font-size: 16px;
    animation: loading 10s linear infinite;
    &::-webkit-scrollbar{
        width: 10px;
    }
    &::-webkit-scrollbar-track {
        width: 10px;
        background-color: $black-900;
      }

      &::-webkit-scrollbar-thumb {
        width: 10px;
        background: rgba($white,0.1);
        border-radius: 20px;
      }
}