@import '../../styles/variables.scss';

.redefine-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 100vh;

  & > h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: -0.5px;
    color: $white;
  }

  & > form {
    max-width: 352px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    & > span {
      color: $main-default;
      cursor: pointer;
    }
  }
}
