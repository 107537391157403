@import '../../../../styles/variables.scss';

.notification-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  max-width: 592px;
  margin: 32px;

  & input:disabled {
    opacity: 0.6;
  }
}

.notification-form__title-space {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 48px;
}

.notification-form__title {
  color: $white;
  font-size: 34px;
  font-weight: 700;
  line-height: 120%;
}

.notification-form__text {
  color: $black-500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.5px;
  text-align: center;
}

.notification-form__form-space,
.notification-form__document-form,
.notification-form__address-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 32px;
}

.notification-form__document-form,
.notification-form__address-form {
  gap: 16px;
}

.notification-form__address-form__title {
  font-size: 14px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.25px;
  color: $white;
  align-self: flex-start;
}

.notification-form__address-form__data-flex {
  width: 100%;
  display: flex;
  gap: 10px;
}

.notification-form__button-space {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.checkbox__space-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  color: white;
  width: 100%;

  p {
    font-size: 14px;
  }
}
