@import '../../styles/variables.scss';

.message__container {
  display: flex;
  flex-direction: column;
  max-width: min(100%, 400px);
  padding: 16px;
  color: $white;
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;

  &--system {
    align-self: flex-start;

    border-top-left-radius: 0;
    background-color: $darkmode-super-dark;

    h3 {
      color: $main-default;
    }
  }

  &--user {
    align-self: flex-end;

    border-top-right-radius: 0;
    background-color: $black-800;
  }
}

.message__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $darkmode-dark;
  margin-bottom: 8px;

  &__title {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 2px;
    line-height: 100%;

    margin-bottom: 8px;
  }

  & button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}

.message__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  overflow-x: auto;

  &__container {
    display: flex;
    gap: 2px;
    align-items: baseline;
  }

  &__text {
    font-size: 14px;
    line-height: 150%;
    position: relative;
    box-sizing: border-box;

    &:not(.message__section__text--system) {
      margin-top: 8px;
      margin-right: 8px;
    }

    &--system {
      max-width: calc(100% - 40px);
    }
  }

  &__beat-loader {
    display: inline-block !important;
    width: 24px;
    transform: translateY(2px);
  }

  &__button {
    align-self: flex-start;
    border: none;
    border-radius: 4px;
    background-color: transparent;
    padding: 4px;
    cursor: pointer;

    &:hover {
      svg {
        stroke: $main-default;
      }
    }
  }
}

.markdown__container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & h1 {
    font-size: 24px;
    color: $main-default;
  }

  & h2 {
    font-size: 20px;
    margin-top: 1rem;
  }

  & h3 {
    margin-top: 1rem;
    font-weight: 500;
  }

  & h4 {
    margin-top: 1rem;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.4px;
  }

  & hr {
    border: 1px solid $black-700;
    width: 100%;
  }

  & strong {
    font-weight: 500;
  }

  & em {
    font-style: italic;
  }

  & a {
    color: $main-default;
    text-decoration: underline;
  }

  & blockquote {
    border-left: 4px solid $main-default;
    padding-left: 16px;
    margin-block: 16px;
  }

  code {
    font-size: 0.9rem;
    background-color: $darkmode-dark;
    color: $main-dark;
    padding: 4px;
    border-radius: 4px;
  }

  & p {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  // LISTS
  & ul {
    list-style: inside;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;

    & li ul,
    & li p {
      list-style: circle inside;
      padding-left: 1rem;

      & li ul,
      & li p {
        list-style: square inside;
        padding-left: 1rem;
      }
    }
  }

  & ol {
    list-style: decimal inside;
    display: block;
    margin: 0;

    & li ul,
    & li p {
      list-style: circle inside;
      padding-left: 1rem;
      display: inline;

      & li ul,
      & li p {
        list-style: square inside;
        padding-left: 1rem;
      }
    }
  }

  // TABLE
  table {
    display: block;
    width: 100%;
    max-height: 50dvh;
    border-collapse: collapse;
    table-layout: fixed;
    overflow-x: auto;
  }

  th,
  td {
    padding: 4px 8px;
    width: 100px;
    text-align: left;
    border-bottom: 1px solid $main-hyper-dark;
  }

  th {
    position: sticky;
    top: 0;
    outline: 1px solid $darkmode-dark;
    background-color: $darkmode-dark;
    color: $main-dark;
    font-weight: 600;
    text-transform: uppercase;
  }

  td {
    border-inline: 1px solid $darkmode-dark;
  }

  tr:nth-child(even) {
    background-color: $darkmode-light;
  }

  tr:nth-child(odd) {
    background-color: $black-800;
  }
}
