@import '../../styles/variables.scss';

.header-progress-bar__container {
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
}

.header-progress-bar__title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > p {
    color: $white;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0.4px;
    margin-top: 1px;

    font-size: 10px;
    text-transform: uppercase;
  }

  & > span {
    color: $white;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.4px;
    margin-top: 1px;
    font-size: 12px;
    text-transform: uppercase;
  }
}

.header-progress-bar__area {
  position: relative;
  overflow: hidden;
  background: $darkmode-dark;
  border-radius: 9999px;
  width: 100px;
  height: 5px;

  transform: translateZ(0);
}

.header-progress-bar__indicator {
  background: rgb(0, 150, 136);
  background: linear-gradient(90deg, rgba(0, 150, 136, 1) 50%, rgba(100, 254, 218, 1) 100%);

  width: 100%;
  height: 100%;
  transition: transform 660ms cubic-bezier(0.65, 0, 0.35, 1);
}

@media (min-width: $screen-sm) {
  .header-progress-bar__area {
    width: 150px;
  }
}
