@import '../../styles/variables.scss';

.scroll-area__root {
  border-radius: 4px;
  overflow: hidden;
  --scrollbar-size: 5px;
}

.scroll-area__viewport {
  width: calc(100% - 16px);
  height: 100%;
  border-radius: inherit;
}

.scroll-area__scroll-bar:hover {
  background: $black-600;
}
.scroll-area__scroll-bar[data-orientation='vertical'] {
  width: var(--scrollbar-size);
}
.scroll-area__scroll-bar[data-orientation='horizontal'] {
  flex-direction: column;
  height: var(--scrollbar-size);
}

.scroll-area__thumb {
  flex: 1;
  background: $black-400;
  border-radius: var(--scrollbar-size);
  position: relative;
}
/* increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html */
.scroll-area__thumb::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  min-width: 44px;
  min-height: 44px;
}

.scroll-area__corner {
  background: $black-600;
}
