@import '../../styles/variables.scss';

.complaints {
  box-sizing: border-box;
  display: flex;
  min-height: calc(100dvh - 75px);
  flex-direction: column;
  gap: 12px;
  color: $white;
  max-width: min(80dvw, 1024px);
  margin-inline: auto;
  padding-block: 40px 64px;

  & fieldset {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-block: 16px;
  }

  & legend {
    font-size: 16px;
    font-weight: 700;
  }
}
