@import '../../styles/variables.scss';

.privacy__footer {
  position: absolute;
  bottom: 0;
  padding: 12px 48px;
  display: flex;
  gap: 16px;
  
  & > a {
    color: $white;
    font-size: 14px;
    text-decoration: none;
    transition: color 0.3s;
    
    &:hover {
      color: $main-default;
    }
  }
}