@import '../../../../styles/variables.scss';

.transcript-text {
  box-sizing: border-box;
  background-color: $darkmode-dark;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;

  &--heading {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    & h3 {
      color: $white;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 2px;
      text-transform: uppercase;
      max-width: 30vw;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & p {
      color: $white;
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.4px;
    }
  }

  &--content {
    display: flex;
    color: $black-300;
    text-align: justify;
    flex-direction: column;
    gap: 16px;
    background-color: $darkmode-light;
    padding: 16px;
    letter-spacing: 0.25px;
    line-height: 150%;
    font-weight: 400;
    font-size: 14px;
    max-height: 70dvh;
    overflow-y: auto;

    &--highlight {
      color: $black-900;
      background-color: $main-dark;
      outline: 4px solid $main-dark;
    }
  }
}

.transcript-text--content--message {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  background-color: black;
  color: $black-300;
  border-radius: 8px;

  header {
    font-weight: 600;
  }

  footer {
    font-weight: 300;
    font-size: 12px;
    align-self: flex-end;
  }

  &__highlight {
    outline: 4px solid $main-default;
  }
}
