@import '../../../../styles/variables.scss';

.pagination-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.pagination__top {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.pagination__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.5px;
  width: 100%;
  color: $black-200;
}

.pagination__button-space {
  display: flex;
  gap: 16px;
}

.pagination__button {
  background: none;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover:not(:disabled) {
    .pagination__button__icon {
      color: $main-hyper-dark;
    }
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.pagination__button__icon {
  width: 24px;
  height: 24px;
  transition: all ease 0.3s;
}

.pagination__cards {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    height: 5px;
    background-color: $black-900;
  }

  &::-webkit-scrollbar-thumb {
    height: 5px;
    background: rgba($white, 0.1);
    border-radius: 5px;
  }
}

.pagination__card,
.pagination__card--current {
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 8px;
  border: 1px dashed $black-700;
  border-radius: 8px;
  transition: all ease 0.3s;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.25px;
    white-space: nowrap;
    color: $white;
  }

  span {
    font-size: 10px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.4px;
    color: $black-500;
  }
}

.pagination__card--current {
  border: 1px solid transparent;
  background: $main-hyper-dark;
  span {
    color: $black-100;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}
