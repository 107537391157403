@import '../../styles/variables.scss';

$spinner: #64feda;
$background: $white;
$size: 200px;

html {
  background-color: $background;
}

.spinner__container {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.spinner {
  top: 0;
  margin-left: auto;
  margin-right: auto;
  width: 48px;
  height: 48px;
  animation: spinner 0.75s linear infinite;
  background: $spinner;

  span {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: $screen-xl) {
  .spinner {
    width: 48px;
    height: 48px;

    .spinner-inner-3 {
      $offset: 48px * 0.1;
      top: $offset;
      left: $offset;
      width: 48px - ($offset * 2);
      height: 48px - ($offset * 2);
    }
  }
}
