@import '../../../../styles/variables.scss';

.participant-snippet {
  background-color: $darkmode-dark;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  padding: 16px;

  &--saved {
    outline: 2px solid $main-default;
  }
}

.participant-snippet__subsection--column {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.participant-snippet__subsection--row {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.participant-snippet__heading {
  display: flex;
  justify-content: space-between;

  & > h4 {
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $black-400;
  }
}

.participant-snippet__content {
  background-color: $darkmode-light;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;

  &-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > p {
      color: $black-400;
      font-size: 10px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.4px;
    }

    & span {
      color: $black-300;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      letter-spacing: 0.4px;
    }

    & > nav {
      display: flex;
      align-items: center;

      & > button {
        border: none;
        padding: 0 6px;
        background-color: transparent;
        cursor: pointer;
        height: 16px;
      }
    }
  }

  &-transcript {
    background-color: $black-900;
    color: $black-300;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.25px;
    height: 88px;
    overflow: auto;
    padding: 8px;
  }
}

.participant-snippet__identification {
  display: flex;
  align-items: center;

  &--label {
    box-sizing: border-box;
    background-color: $darkmode-light;
    padding: 8px 16px 12px;
    min-width: 40%;

    & > h6 {
      color: $black-300;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.5px;
    }

    & > p {
      color: $black-400;
      font-size: 10px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.4px;
    }
  }
}

.dotted-connector {
  width: 20%;
  border-top: 2px dotted $main-default;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background-color: $main-default;
    border-radius: 50%;
    position: absolute;
    top: -5px;
  }

// with a triangle shape

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 10px solid $main-default;
    position: absolute;
    top: -6px;
    right: -1px;
  }
}
