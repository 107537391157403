@import '../../styles/variables.scss';

.contracts {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 140px);
  margin: 32px;
  box-sizing: border-box;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
    max-width: 592px;
    gap: 20px;
  }

  textarea {
    resize: none;
    height: 400px;
    border-color: white;
    background-color: transparent;
    padding: 30px;
    font-family: $font-primary;
    font-weight: 400;
    color: $white;
    font-size: 18px;
  }
}

.contracts__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  width: 100%;
}
