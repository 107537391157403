@import '../../styles/variables.scss';

.login__inputs {
  display: flex;
  flex-direction: column;
  gap: 48px;
  justify-content: center;
  width: 80%;

  main {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 48px;

    h2 {
      color: #fff;
      font-feature-settings: 'clig' off, 'liga' off;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 120%;

      span {
        color: #fff;
        font-size: 28px;
        font-weight: 500;
      }
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;

    p {
      color: $main-default;
      font-size: 10px;
      font-weight: 300;
      line-height: 140%;
      letter-spacing: 0.4px;
      text-transform: uppercase;
    }

    a {
      color: var(--neutral-white, #fff);
      font-size: 10px;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0.4px;
      text-transform: uppercase;
    }
  }
}

.login__inputs_container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 0;
  align-items: center;

  a {
    color: #fff;
    font-size: 14px;
  }

  a:hover {
    cursor: pointer;
    text-decoration: underline;
    color: $main-default;
  }
}

.login__form__disclaimer {
  font-size: 12px;
  color: white;

  a {
    font-size: 12px;
    text-decoration: underline;
  }
}

@media (min-width: $screen-lg) {
  .login__inputs {
    width: 380px;
  }
}
