//Colors
$main-super-light: #d8fff6;
$main-light: #aeffec;
$main-default: #64feda;
$main-dark: #1ae5b5;
$main-super-dark: #00b88d;
$main-hyper-dark: #009688;

$text-primary: #3b3b3b;
$text-secondary: #757474;
$text-disabled: #bfbfbf;

$darkmode-super-dark: #1b1b1b;
$darkmode-dark: #272929;
$darkmode-light: #3d4040;
$darkmode-super-light: #4a4a4a;

$bg-main: #f0fffe;
$bg-secondary: #f3f7f9;
$bg-info: #effaff;
$bg-success: #f9fff4;
$bg-warning: #fffcf3;
$bg-error: #fff4f4;

$black-900: #1b1b1b;
$black-800: #3b3b3b;
$black-700: #5a5a5a;
$black-600: #6d6d6d;
$black-500: #959595;
$black-400: #b5b5b5;
$black-300: #d9d9d9;
$black-200: #e9e9e9;
$black-100: #f2f2f2;
$black-75: #f5f5f5;
$black-50: #fafafa;
$white: #ffffff;

$info-default: #006192;
$warning-default: #ffb400;
$error-default: #e73e39;
// Fonts
$font-primary: 'Roboto', sans-serif;

// Screens
$screen-xs: 360px;
$screen-sm: 480px;
$screen-md: 720px;
$screen-lg: 960px;
$screen-xl: 1140px;

$error: #ff8383;

$color-blue-800: #16b1ff;

$color-green: #26a69a;

$color-black: #0d0d0d;

$color-yellow-50: #fffeea;
$color-yellow-700: #fac74c;
$color-yellow-800: #ffb400;
$color-yellow-900: #d77b2b;

:root {
  --toastify-toast-width: 360px;
  --toastify-color-success: #64feda;
  --toastify-color-error: #ff8383;
}
