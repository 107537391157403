@import '../../styles/variables.scss';

.chat-viewer {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 70vh;
  flex-direction: column;
  position: relative;
  justify-content: flex-end;
  overflow: hidden;
}

.chat-viewer__messages {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  max-height: calc(100% - 106px);
  overflow-y: auto;
  box-sizing: border-box;
  gap: 24px;
  position: relative;

  .spinner-container {
    align-self: center;
  }
}

.chat-viewer__suggestions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
  gap: 8px;
  margin-bottom: 16px;
}

.chat-viewer__back-space {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}

.chat-viewer__back-space__input-space {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding: 16px 0 0;
}

.chat-viewer__back-space__input-space__bar {
  width: 100%;
  color: $white;
  padding: 16px;
  box-sizing: border-box;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.25px;

  border: none;
  border-radius: 4px;
}

.chat-viewer__back-space__input-space__button {
  background: $main-default;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
  transition: all ease 0.3s;

  &:disabled {
    background: $darkmode-light;
    cursor: not-allowed;
    &:hover {
      background: $darkmode-light;
    }
  }

  svg {
    color: $darkmode-dark;
  }

  &:hover {
    background: $main-dark;
  }
}

.chat-viewer__footer {
  background-color: transparent;
  color: $text-disabled;
  font-size: 10px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.4px;
  font-feature-settings: 'clig' off, 'liga' off;
  text-align: center;
}

.chat-viewer__messages-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  max-height: unset;
  position: relative;
  gap: 8px;

  &::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: $screen-xl) {
  .chat-viewer {
    min-height: unset;
  }

  .chat-viewer__messages-container {
    max-height: 100%;
  }
}

@media (min-width: $screen-lg) {
  .chat-viewer__messages {
    gap: 32px;
    margin-bottom: 0;
  }

  .chat-viewer__back-space__input-space {
    padding: 16px 0 0;
  }

  .chat-viewer__suggestions-container {
    grid-template-columns: 1fr 1fr;
  }
}
