@import '../../styles/variables.scss';

.dialog__overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  animation: overlayShow 500ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 11;
}

.dialog__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: $black-900;
  // border-radius: 8px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 500ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 12;
}

.dialog__title {
  margin: 0;
  color: $white;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.4px;
}

.dialog__description {
  color: #959595;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.4px;
}

.dialog__label__description {
  color: $white;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.4px;
}

.dialog__button {
  width: 100%;
}

.close__button {
  // border-radius: 100%;
  padding: 0;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $white;
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  // background-color: $black-800;
}
.close__button:hover {
  // background-color: $main-dark;
  cursor: pointer;
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@media (min-width: $screen-lg) {
  .dialog__content {
    width: 90vw;
  }
  .accept__button.green {
    width: 150px;
  }

  .dialog__button {
    width: 180px;
  }
}
