@import '../../../styles/variables.scss';

.input__wrapper {
  width: 100%;
  position: relative;
  // background-color: inherit;

  &--disabled {
    // background-color: $black-200;
    border-color: $black-300;
    color: $black-600;

    & > svg {
      pointer-events: none;
    }
  }

  &--error .input {
    border-color: $error;
  }

  &--error .input__placeholder {
    color: $error;
  }

  &:focus-within > svg {
    color: $main-default;
  }

  > svg {
    color: $black-500;
    cursor: pointer;
    position: absolute;
    right: 12px;
    bottom: 16px;
    transition: all 0.3s ease;
  }
}

.input {
  align-items: center;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid $black-300;
  box-sizing: border-box;
  color: $white;
  display: flex;
  font-size: 14px;
  height: 55px;
  justify-content: center;
  min-height: 55px;
  outline: none;
  padding: 0 16px;
  width: 100%;

  &:hover:not([disabled]) {
    border-color: $black-500;
  }

  &:focus:not([disabled]),
  &:active:not([disabled]) {
    border-color: $main-default;
  }

  &::placeholder {
    color: $black-500;
    transition: all 0.3s ease;
    opacity: 0;
  }

  &:focus::placeholder {
    opacity: 0.6;
  }

  &:autofill,
  &:autofill:hover,
  &:autofill:focus,
  &:autofill:active {
    background-color: $darkmode-super-dark !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px $darkmode-super-dark inset !important;
    box-shadow: 0 0 0 60px $darkmode-super-dark inset !important;
    -webkit-text-fill-color: white;
  }

  &::-webkit-validation-bubble {
    display: none;
    opacity: 0;
  }

  // &--hide-placeholder::placeholder {
  //   color: transparent;
  // }
}

.input__label {
  align-items: center;
  color: $black-300;
  cursor: default;
  font-size: 14px;
  left: 17px;
  max-height: 50px;
  max-width: calc(100% - 60px);
  min-width: 0;
  opacity: 0.6;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 19px;
  transition: all 0.2s ease;
  white-space: nowrap;
  z-index: 1;

  &--open {
    // background: linear-gradient(
    //   0deg,
    //   rgba(255, 255, 255, 0) 0%,
    //   rgba(255, 255, 255, 0) 50%,
    //   white 0%,
    //   white 59%,
    //   rgba(255, 255, 255, 0) 0,
    //   rgba(255, 255, 255, 0) 100%
    // ); // top: 0;

    // background-color: $black-900;
    // background-color: inherit;
    font-size: 12px;
    left: 12px;
    max-width: calc(100% - 36px);
    opacity: 1;
    padding: 0 4px;
    top: -7px;
    z-index: 2;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px) contrast(1.13);
    border-radius: 4px;
  }

  &--focus {
    color: $main-default !important;
  }

  &--none {
    display: none;
  }
}

.input__icon {
  position: absolute;
  right: 16px;
  top: 15px;
}

.input__password-eye {
  position: absolute;
  top: 17px;
  right: 16px;
  color: $main-default;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
  }
}

.input:-webkit-autofill {
  animation-name: onAutoFillStart;
}

.input:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}

.input__spinner-container {
  position: absolute;
  right: 16px;
  top: 16px;
}

@keyframes onAutoFillStart {
}

@keyframes onAutoFillCancel {
}
