@import '../../../../styles/variables.scss';

.pdf__controller {
  background-color: $darkmode-super-dark;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  padding: 10px;
  position: relative;
  z-index: 1;
}

.pdf__controller--icon,
.page__display--current {
  background-color: $darkmode-dark;
  border: none;
  border-radius: 2px;
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
}

.pdf__controller--icon {
  color: $main-default;
  cursor: pointer;
}

.icon--off {
  color: $text-disabled;
  cursor: not-allowed;
}

.page__display {
  color: $white;
  font-weight: 700;
  font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;

  & > input {
    border: none;
    font-weight: 700;
    font-family: $font-primary;
    font-size: 1rem;
    text-align: center;
    padding: 0;
  }
}
