@import '../../styles/variables.scss';

/* General rules */

.login {
  flex-direction: column-reverse;

  section {
    width: 100%;
    height: 100vh;
    display: flex;
  }

  section:last-child {
    display: none;
  }
}

/* Left Side */

.login__inputs_section {
  justify-content: center;
  align-items: center;
}

/* Right Side */

.login__welcome_section {
  background-color: black;
  justify-content: flex-start;
  align-items: center;
  padding-left: 64px;
  box-sizing: border-box;
  position: relative;
}

.login__welcome {
  display: flex;
  flex-direction: column;
  gap: 32px;
  z-index: 1;

  h1 {
    color: #fff;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.5px;

    span {
      font-weight: 600;
    }
  }

  p {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
}

@media screen and (min-width: $screen-lg) {
  .login {
    display: flex;
    flex-direction: row;

    section {
      width: 50%;
      height: 100vh;
      display: flex;
    }

    section:last-child {
      display: flex;
    }
  }
}