@import '../../../styles/variables.scss';

.select__wrapper {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  &--disabled {
    // background-color: $black-200;
    border-color: $black-300;
    color: $black-600;
    pointer-events: none;
  }

  &--error .select {
    border-color: $error;
  }

  &--error .select__placeholder {
    color: $error;
  }

  &:not(.select__wrapper--wrapped-error) {
    &:focus-within>svg {
      color: $main-default;
    }
  }

  svg {
    color: $black-400;
    cursor: pointer;
    position: absolute;
    right: 12px;
    transition: all 0.3s ease;
  }
}

.select {
  align-items: center;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid $black-300;
  box-sizing: border-box;
  color: $white;
  display: flex;
  font-family: $font-primary;
  font-size: 14px;
  height: 55px;
  justify-content: center;
  min-height: 55px;
  outline: none;
  padding: 0 46px 0 16px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;

  &>option {
    color: black;
  }

  &:hover {
    border-color: $black-400;
  }

  &:not(.select--wrapped-error) {

    &:focus,
    &:active {
      border-color: $main-default;
    }
  }

  &__without-label {
    border-color: $black-300;
  }

  &::placeholder {
    color: $black-400;
    font-family: $font-primary;
    transition: all .3s ease;
    opacity: 0;
  }

  &:focus::placeholder {
    opacity: 0.6;
  }

  &:autofill,
  &:autofill:hover,
  &:autofill:focus,
  &:autofill:active {
    background-color: $main-super-light !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px $main-super-light inset !important;
    box-shadow: 0 0 0 60px $main-super-light inset !important;
  }

  &--empty {
    color: $black-400;
    // opacity: 0.6;
  }

  &--hide-placeholder {
    color: transparent;
  }

  &__without-label--empty {
    color: $black-600;
  }
}

.select__label {
  align-items: center;
  color: $black-300;
  cursor: default;
  font-family: $font-primary;
  font-size: 14px;
  left: 17px;
  max-height: 50px;
  max-width: calc(100% - 60px);
  min-width: 0;
  opacity: 0.6;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 19px;
  transition: all .2s ease;
  white-space: nowrap;
  z-index: 1;

  &--open {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 50%, $black-900 0%, $black-900 59%, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0) 100%); // top: 0;
    font-size: 12px;
    left: 12px;
    max-width: calc(100% - 36px);
    opacity: 1;
    padding: 0 4px;
    top: -7px;
    z-index: 2;
  }

  &--focus {
    color: $main-default !important;
  }
}

.select:-webkit-autofill {
  animation-name: onAutoFillStart;
}

.select:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}

@keyframes onAutoFillStart {}

@keyframes onAutoFillCancel {}