@import '../../../styles/variables.scss';

.message__detail {
  box-sizing: border-box;
  position: fixed;
  background-color: $darkmode-super-dark;
  color: $white;
  border: 1px solid $main-dark;
  border-radius: 0 8px 8px 8px;
  padding: 32px;
  max-height: 80dvh;
  width: max(50dvw, 800px);
  max-width: 90dvw;
  overflow-y: auto;
  z-index: 1000;
  top: 82px;
  left: 16px;
  cursor: grab;
}