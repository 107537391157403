@import '../../styles/variables.scss';

.custom-tooltip__trigger {
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  text-align: left;
}

.tooltip__content {
  width: 240px;
  padding: 8px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);
  z-index: 9999;

  p {
    width: 100%;
    display: block;
    color: $white;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 8px;
  }

  span {
    color: $white;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0.2px;
  }
}

.tooltip__arrow {
  polygon {
    fill: rgba(255, 255, 255, 0.25);
  }
}
