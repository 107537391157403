@import '../../../styles/variables.scss';

.alert__container {
  display: flex;
  align-items: center;
  align-self: stretch;
  color: white;

  &--hidden {
    display: none;
  }

  &--success {
    border: 2px solid $main-light;
  }

  &--info {
    border: 2px solid $info-default;
  }

  &--warn {
    border: 2px solid $warning-default;
  }

  &--error {
    border: 2px solid $error-default;
  }

  &--promise {
    border: 2px solid $black-200;
  }
}

.alert__icon-wrapper {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  width: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  &--success {
    border-right: 2px solid $main-light;
  }

  &--info {
    border-right: 2px solid $info-default;
  }

  &--warn {
    border-right: 2px solid $warning-default;
  }

  &--error {
    border-right: 2px solid $error-default;
  }

  &--promise {
    border-right: 2px solid $black-200;
  }

  & > .spinner-container > span {
    border-color: $black-500 $black-500 transparent !important;
    margin-top: 8px;
  }
}

.alert__content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px 12px;
  gap: 8px;

  & > p {
    font-size: 12px;
  }
}

.alert__header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > h3 {
    font-size: 16px;
    font-weight: 700;
  }
}

.alert__close {
  background-color: transparent;
  cursor: pointer;
}
