@import '../../../styles/variables.scss';

// Outlined-tabs
.outlined-tabs {
  display: flex;
  position: relative;
  display: flex;
  width: 100%;

  &--mobile-full {
    @extend .outlined-tabs;
  }
}

.outlined-tabs__item {
  text-transform: uppercase;
  padding: 16px;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background-color: transparent;
  color: $black-500;
  letter-spacing: 1px;
  width: fit-content;
  border: none;

  &--selected {
    color: $main-default;
  }

  &--disabled {
    color: $black-300;
    cursor: not-allowed;
  }

  & > svg {
    display: inline-block;
  }

  & > span {
    display: none;
  }
}

.outlined-tabs__choice {
  display: block;
  height: 2px;
  width: 100%;
  background-color: $main-default;
  position: absolute;
  bottom: 0px;
  pointer-events: none;
  transition: all 0.5s ease-in-out;
}

// Contained-tabs
.contained-tabs__container {
  display: flex;
  align-items: center;
  gap: 8px;

  background-color: $black-200;
  border-radius: 8px;
  padding: 4px;
}

.contained-tabs__tab {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  background-color: transparent;

  color: $text-primary;
  font-size: 12px;
  font-weight: 500;

  cursor: pointer;

  transition: background-color 0.3s ease-in-out;

  &--selected {
    background-color: $white;
  }

  & > svg {
    display: inline-block;
  }

  & > span {
    display: none;
  }
}

// Outlined-tabs media-queries
@media screen and (min-width: $screen-md) {
  .outlined-tabs__item {
    & > svg {
      display: none;
    }

    & > span {
      display: inline-block;
    }
  }
}

// Contained-tabs media-queries
@media screen and (min-width: $screen-sm) {
  .contained-tabs__tab {
    padding: 8px;

    & > svg {
      display: none;
    }

    & > span {
      display: inline-block;
    }
  }
}

@media screen and (min-width: $screen-md) {
  .contained-tabs__tab {
    padding: 8px 16px;
  }
}

@media (max-width: $screen-lg) {
  .outlined-tabs--mobile-full {
    justify-content: space-between;
    border-bottom: 1px solid $black-200;
    .outlined-tabs__item {
      width: 100%;
      & > svg {
        display: none;
      }

      & > span {
        display: flex;
        justify-content: center;
      }
    }
  }
}
