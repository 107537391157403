@import '../../../../styles/variables.scss';

@keyframes sparkle {
  0% {
    color: $text-disabled;
  }
  50% {
    color: $main-dark;
  }
  100% {
    color: $text-disabled;
  }
}

@keyframes spin {
  0% {
    transform: translateX(-50%) scaleX(1);
  }
  100% {
    transform: translateX(-50%) scaleX(-1);
  }
}

@keyframes pulse {
  0% {
    outline-width: 10px;
  }
  50% {
    outline-width: 40px;
  }
  100% {
    outline-width: 10px;
  }
}

@keyframes smallPulse {
  0% {
    outline-width: 0px;
  }
  50% {
    outline-width: 20px;
  }
  100% {
    outline-width: 0px;
  }
}

.file-summary {
  box-sizing: border-box;
  background-color: $darkmode-super-dark;
  color: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 128px;
  min-height: 60dvh;
  padding: 24px;
  flex: 1;
}

.file-summary--info {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & h3 {
    color: $white;
    font-weight: 500;
    letter-spacing: 0.3px;
    font-size: 16px;
  }

  & p {
    color: $text-disabled;
    letter-spacing: 0.3px;
    font-size: 12px;
  }
}

.summary__button {
  box-sizing: border-box;
  background-color: $darkmode-dark;
  border: 2px solid $main-super-dark;
  outline: 10px solid $darkmode-super-light;
  border-radius: 50%;
  height: 180px;
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 32px;
  color: $text-disabled;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 2px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    border-color: $main-default;

    .main-sparkle {
      color: $main-default;
    }
  }

  &--animated {
    border-color: $main-default;
    animation: pulse 4s infinite;

    ::before {
      content: '';
      position: absolute;
      top: -34px;
      height: 180px;
      width: 180px;
      outline: 2px solid #00b88d67;
      border-radius: 50%;
      z-index: 0;
      transform: translateX(-50%);
      animation: smallPulse 3s infinite;
    }

    .main-sparkle {
      animation: sparkle 3s infinite;
    }
    .minor-sparkle--down {
      animation: sparkle 3s infinite, spin 1s infinite;
    }
    .minor-sparkle--up {
      animation: sparkle 3s infinite, spin 1s infinite;
    }
  }
}

.sparkle__container {
  position: relative;
  width: 80px;
  height: 120px;
}

.sparkle {
  position: absolute;
  color: $text-disabled;
  transition: all 0.3s;
}

.minor-sparkle--up,
.minor-sparkle--down {
  width: 24px;
  height: 24px;
}

.minor-sparkle--up {
  top: 5px;
  left: 15px;
  transform: translateX(-50%);
}

.minor-sparkle--down {
  bottom: 5px;
  right: -5px;
  transform: translateX(-50%);
}

.main-sparkle {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  stroke-width: 0.5px;
  width: 72px;
  height: 72px;
}
