@import '../../styles/variables.scss';

.title-space-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 100%;
  max-width: 631px;
  margin-bottom: 48px;
}

.title {
  color: $white;
  font-size: 34px;
  font-weight: 700;
  line-height: 120%;
}

.subtitle {
  color: $black-500;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.5px;
  text-align: center;
}
