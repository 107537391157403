@import '../../styles/variables.scss';

.legacy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $info-default;
  border-radius: 2px;
  border: none;
  padding: 8px 16px;
  text-align: left;
  width: 100%;
  min-height: 70px;
  justify-content: center;

  transition: all 0.2s ease-in-out;
}

.legacy__title {
  color: white;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 4px;
}

.legacy__description {
  color: white;
  font-size: 12px;
  line-height: 150%;
}
