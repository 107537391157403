@import 'reset.css';
@import 'variables.scss';

:root {
  color-scheme: dark;
}

html {
  scroll-behavior: smooth;
  background-color: $darkmode-super-dark;
}

body {
  background-color: $darkmode-super-dark;
  font-family: $font-primary;
  font-weight: 300;
  color: $text-primary;
  line-height: 1.35;
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100vh;
}

button {
  font-family: $font-primary;
}

*:focus {
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus {
    color: inherit;
  }
}

h1 {
  font-size: 24px;
  line-height: 1.35em;
  font-weight: 600;
}

h2 {
  font-size: 22px;
  line-height: 1.35em;
  font-weight: 500;
}

h3 {
  font-size: 15px;
  line-height: 1.35em;
}

h4 {
  font-size: 12px;
  line-height: 1.35em;
  font-weight: 500;
}

.row {
  display: flex;
  flex-direction: row;
  // height: 100%;
}

.column {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 0 5%;
  width: 90%;
  max-width: 1280px;
}

.react-switch {
  margin-bottom: -4px;
}

.no-show--xl {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

// ::selection {
//   color: $color-gray-800;
//   background: $color-green-a200;
// }

@media (min-width: $screen-xl) {
  h1 {
    font-size: 36px;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 13px;
  }
}

@media (min-width: $screen-xl) {
  .no-show--xl {
    opacity: 1;
    position: inherit;
    pointer-events: unset;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(5px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
