@import '../../../styles/variables.scss';

.textarea__wrapper {
  width: 100%;
  position: relative;

  &--disabled {
    // background-color: $black-200;
    border-color: $black-300;
    color: $black-600;
  }

  & .textarea {
    resize: none;
    min-height: 198px;
  }

  &--error .textarea {
    border-color: $error;
  }

  &--error .textarea__placeholder {
    color: $error;
  }

  &--resize-vertical .textarea {
    resize: vertical;
  }

  &--resize-horizontal .textarea {
    resize: horizontal;
  }

  &--auto-resize .textarea {
    resize: none;
    min-height: unset;
    height: 52px;
  }
}

.textarea {
  align-items: center;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid $black-300;
  box-sizing: border-box;
  color: $white;
  display: flex;
  font-family: $font-primary;
  font-size: 14px;
  justify-content: center;
  outline: none;
  padding: 16px;
  width: 100%;
  overflow-x: hidden;

  &:hover {
    border-color: $black-400;
  }

  &:focus,
  &:active {
    border-color: $main-default;
  }

  &::placeholder {
    color: $black-400;
    text-align: justify;
    font-family: $font-primary;
    transition: all 0.3s ease;
    opacity: 0;
  }

  &:focus::placeholder {
    opacity: 0.6;
  }

  &:autofill,
  &:autofill:hover,
  &:autofill:focus,
  &:autofill:active {
    background-color: $main-super-light !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 60px$main-super-light inset !important;
    box-shadow: 0 0 0 60px$main-super-light inset !important;
  }

  &::-webkit-validation-bubble {
    display: none;
    opacity: 0;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: $black-400;
  }
}

.textarea__wrapper {
  &--without-label {
    & .textarea::placeholder {
      opacity: 1;
    }
  }
}

.textarea__label {
  align-items: center;
  color: $black-300;
  cursor: default;
  font-family: $font-primary;
  font-size: 14px;
  left: 17px;
  max-height: 50px;
  max-width: calc(100% - 60px);
  min-width: 0;
  opacity: 0.6;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 19px;
  transition: all 0.2s ease;
  white-space: nowrap;
  z-index: 1;

  &--open {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 50%,
      $black-900 0%,
      $black-900 59%,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0) 100%
    ); // top: 0;
    font-size: 12px;
    left: 12px;
    max-width: calc(100% - 36px);
    opacity: 1;
    padding: 0 4px;
    top: -7px;
    z-index: 2;
  }

  &--focus {
    color: $main-default !important;
  }
}

.textarea:-webkit-autofill {
  animation-name: onAutoFillStart;
}

.textarea:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}

.textarea__wrapper__separator {
  margin-left: 16px;
  position: absolute;
  margin-top: 1px;
  height: 13px;
  width: calc(100% - 32px);
  background-color: transparent;
}

.mic-button {
  position: absolute;
  right: 4px;
  bottom: 4px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  padding: 4px;
  transition: all 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

.mic-button--active {
  opacity: 1;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
    height: 24px;
    border: 2px solid $main-dark;
    border-radius: 50%;
    animation: wave 1.5s infinite;
  }
}
.listening__dropdown {
  position: absolute;
  border-radius: 50%;
  right: 2px;
  bottom: 4px;
  padding: 4px;
}

@keyframes wave {
  0% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  90% {
    transform: translate(-50%, -50%) scale(2);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

@keyframes onAutoFillStart {
}

@keyframes onAutoFillCancel {
}
