@import '../../../styles/variables.scss';

.checkbox__container {
  display: flex;
  align-items: center;
}

.checkbox {
  cursor: pointer;
  display: inline-block;
  height: 18px;
  overflow: hidden;
  position: relative;
  width: 18px;
  min-width: 18px;

  &--disabled {
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  }
}

.checkbox input[type='checkbox'] {
  display: none;
}

.checkbox__checkmark {
  align-items: center;
  border-radius: 2px;
  border: 2px solid $black-700;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  justify-content: center;
  transition: all 0.2s ease-in;
  width: 100%;

  &--checked {
    background-color: $main-default;
    border-color: $main-default;
  }

  svg {
    height: 14px;
    width: 14px;
    pointer-events: none;
  }
}

.checkbox__label {
  color: $black-300;
  font-size: 14px;
  font-weight: 400;
  margin-left: 8px;
  user-select: none;
}