@import '../../../../styles/variables.scss';

.react-pdf__message--no-data {
  height: 100%;
  width: 100%;
  background-color: $darkmode-super-light;
  font-family: $font-primary;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
}
