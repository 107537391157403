@import '../../styles/variables.scss';

.tech-documentation__main {
  max-width: 70dvw;
  margin: 48px auto 144px;
  color: $white;
}

.tech-documentation__markdown__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  letter-spacing: 0.4px;

  h1 {
    font-size: 3rem;
    color: $main-dark;
  }

  h2 {
    font-size: 1.8rem;
    margin-top: 56px;
  }

  h3 {
    margin-top: 16px;
    font-size: 1.2rem;
    color: $main-dark;
  }

  h4 {
    margin-block: 16px;
    font-size: 1rem;
    color: $main-dark;
  }

  p {
    font-size: 1rem;
    line-height: 200%;
    text-align: justify;
  }

  code {
    font-size: 0.9rem;
    background-color: $darkmode-dark;
    color: $main-dark;
    padding: 4px;
    border-radius: 4px;
  }

  a {
    color: $main-super-dark;
    text-decoration: underline;
    transition: color 0.2s linear;

    &:hover {
      color: $main-dark;
    }
  }

  li {
    list-style: inside;
  }

  // TABLE
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 1rem;
  }
  th,
  td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid $main-dark;
  }
  th {
    background-color: $darkmode-dark; /* Heading background color */
    color: $main-dark;
    font-weight: 600;
    text-transform: uppercase;
  }
  tr:nth-child(even) {
    background-color: $darkmode-light; /* Even row background color */
  }
  tr:nth-child(odd) {
    background-color: $darkmode-super-light; /* Odd row background color */
  }
}

.tech-documentation__separator {
  border: none;
  border-bottom: 2px dashed $main-dark;
  margin: 64px 0 8px;
}
