@import '../../../styles/variables.scss';

.container__date-picker {
  display: flex;
  flex-direction: column;
  width: 100%;

  &--custom-header {
    .custom-calendar {
      gap: 32px;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }
}

.custom-calendar {
  border: none;
  border-radius: 4px;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.3);
  padding: 16px;
}

.react-datepicker__month {
  margin: 0;
  margin-top: 8px;

  .react-datepicker__week {
    display: flex;
  }

  .custom-day {
    font-size: 12px;
    font-weight: 300;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    margin: 0;
  }

  .react-datepicker__day--today {
    font-weight: 900 !important;
  }
}

.custom-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 8px 22px 8px;
  border-radius: none;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  text-transform: capitalize;
  color: $black-800;

  button {
    border: none;
    width: 24px;
    background: transparent;
    cursor: pointer;

    svg {
      color: black;
    }
  }
}

.react-datepicker__header {
  background: transparent;
  border: none;
  padding: 0;

  .react-datepicker__day-names {
    font-weight: 500;
    font-size: 10px;
    line-height: 16px;
    display: flex;

    > * {
      color: $black-500;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      margin: 0;
    }
  }
}

/* Styles of datepicker */
.custom-picker,
.custom-calendar,
.react-datepicker,
.react-datepicker__portal {
  z-index: 3;
  display: flex;

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: $main-hyper-dark;
  }

  .react-datepicker__current-month {
    text-transform: capitalize;
  }

  .react-datepicker__month-container {
    font-family: 'Roboto', sans-serif;
  }
}

.react-datepicker__time-container {
  margin-left: 16px;
  border-left: 1px solid $black-100;

  .react-datepicker__header {
    border-radius: 0;
    padding: 8px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__time-box {
    overflow-x: visible !important;
  }

  .react-datepicker__time-list {
    overflow: overlay;
    width: 101px !important;

    &::-webkit-scrollbar {
      display: block;
      background: transparent;
      width: 16px;
    }

    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #b5b5b5;
    }
  }

  .react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;

    &--selected {
      background-color: $main-hyper-dark !important;
    }
  }
}

.react-datepicker-time__header {
  font-size: 14px !important;
}

.react-datepicker__day--outside-month {
  background-color: transparent !important;
  color: $black-300;
  pointer-events: none;
}
