@import '../../../../styles/variables.scss';

.editor-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.editor__title{
    font-size: 16px;
    font-weight: 500;
    line-height: 140%; 
    letter-spacing: 0.5px;
    color: $white;
}

.editor{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $white;

    textarea{
        width: 100%;
        height: 100px;
        box-sizing: border-box;
        border: none;
        background: none;
        font-family: $font-primary;
        font-size: 16px;
        font-weight: 400;
        line-height: 140%; 
        letter-spacing: 0.5px;
        resize: none;
    }
}

.editor__button-container{
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.editor__footer{
    width: 100%;
    display: flex;
    justify-content: flex-end;

    p,span{
        font-size: 16px;
        font-weight: 400;
        line-height: 140%;
        letter-spacing: 0.5px;
        color: $black-200;
    }

    span{
        font-weight: 500;
        color: $white;
    }
}

