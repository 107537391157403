@import '../../styles/variables.scss';

.document-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 140px);
}

.document__column {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  width: 90vw;
  max-width: 631px;
  margin: 32px;
  gap: 16px;
  height: 100%;
}

.document__info {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.document__result {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.document__result__title-space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.document__result__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.5px;
  color: $white;
}

.document__result__icon-space {
  display: flex;
  align-items: center;
  gap: 8px;
}

.document__result__button {
  border: none;
  border-radius: 4px;
  background-color: rgb($main-super-light, 0.1);
  padding: 8px;
  transition: all ease 0.3s;
  cursor: pointer;
  svg {
    color: $white;
    width: 20px;
    height: 20px;
  }
  &:hover {
    svg {
      color: $main-default;
    }
  }
}

.document__result__text {
  width: 100%;
  height: 462px;
  border: 1px solid $white;
  padding: 30px;
  box-sizing: border-box;
  color: $white;
  font-size: 16px;
  overflow-y: scroll;

  & p {
    overflow: unset;
  }

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    width: 10px;
    background-color: $black-900;
  }

  &::-webkit-scrollbar-thumb {
    width: 10px;
    background: rgba($white, 0.1);
    border-radius: 20px;
  }
}
