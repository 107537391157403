@import "../../styles/variables.scss";

.pagination {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: row;
  list-style: none;
  margin-top: 12px;

  li {
    width: 32px;
    height: 32px;
    border: 1px solid transparent;
    border-radius: 4px;
    color: $black-600;
    font-size: 14px;
    font-weight: 400;
    margin: 0 1px;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    &:hover {
      color: #fff;
      border: 1px solid $darkmode-light;
    }

    svg {
      color: $main-default;
      padding: 0;
      cursor: pointer;
    }
  }

  li.pagination__active {
    color: $white;
  }
}

.pagination__active {
  background-color: $darkmode-dark;
  border: 1px solid $darkmode-dark;
  border-radius: 4px;

  a {
    font-weight: 700;
  }
}
.pagination__break-me {
  pointer-events: none;
}

@media screen and (min-width: $screen-lg) {
  .pagination {
    li {
      margin: 0 4px;
    }
  }
}
