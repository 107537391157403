@import '../../styles/variables.scss';

.new-front-banner {
  width: 100%;
  height: 75px;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 75px;
  z-index: 999;
  padding-right: 60px;

  & > p {
    color: $white;
    font-size: 12px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.4px;
  }

  & > a {
    box-sizing: border-box;
    padding: 8px;
    min-width: 40px;
    min-height: 40px;
    background-color: $main-default;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    transition: background-color ease 250ms;

    color: $text-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-wrap: nowrap;
    cursor: pointer;

    &:hover {
      background-color: $main-dark;
    }
  }

  &__close-button {
    position: absolute;
    top: 25px;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    border: none;
  }
}

@media screen and (min-width: $screen-lg) {
  .new-front-banner {
    & > a {
      padding: 8px 16px;
    }
  }
}
