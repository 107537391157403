@import '../../../styles/variables.scss';

.button {
  align-items: center;
  background-color: transparent;
  // border-radius: 4px;
  border: 1px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  gap: 8px;
  justify-content: center;
  letter-spacing: 2px;
  line-height: 19px;
  padding: 10px 16px;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  width: fit-content;
  max-height: 40px;

  span {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--icon-right {
    flex-direction: row-reverse;
  }

  &--only-icon {
    svg {
      position: absolute;
    }
  }

  &--rounded {
    width: 32px;
    height: 32px;
    border-radius: 50%;

    &--large {
      width: 48px;
      height: 48px;
    }

    // svg {
    //   min-width: 22px;
    //   min-height: 22px;
    //   width: 22px;
    //   height: 22px;
    //   pointer-events: none;
    // }
  }

  &--bordered {
    border-radius: 4px;
  }

  &--full-width {
    width: 100%;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--text {
    @mixin colorTextVariations(
      $name,
      $colorPrimary,
      $colorHover,
      $colorActive,
      $colorMedium,
      $colorDisabled
    ) {
      &--#{$name} {
        color: $colorPrimary;
        background-color: transparent;

        &:hover {
          color: $colorHover;
          background-color: #{$colorMedium}#{'10'};
        }

        &:active {
          color: $colorActive;
          background-color: #{$colorMedium}#{'25'};
        }

        &:disabled {
          color: $colorDisabled;
          background-color: transparent;
          pointer-events: none;
          cursor: not-allowed;
        }

        > .loading > .loading__dot {
          background-color: $colorMedium;
        }
      }
    }

    @include colorTextVariations(
      'green',
      $main-default,
      $main-dark,
      $main-super-dark,
      $main-light,
      $text-disabled
    );

    @include colorTextVariations(
      'error',
      $error-default,
      $error-default,
      $error-default,
      $error-default,
      $text-disabled
    );
  }

  &--outlined {
    padding: 12px 24px;

    @mixin colorOutlinedVariations(
      $name,
      $colorPrimary,
      $colorHover,
      $colorActive,
      $colorMedium,
      $colorDisabled
    ) {
      &--#{$name} {
        color: $colorPrimary;
        background-color: transparent;
        border-color: $colorPrimary;

        &:hover {
          color: $colorHover;
          background-color: #{$colorMedium}#{'10'};
          border-color: $colorHover;
        }

        &:active {
          color: $colorActive;
          background-color: #{$colorHover}#{'25'};
          border-color: $colorActive;
        }

        &:disabled {
          color: $colorDisabled;
          background-color: transparent;
          border-color: $colorDisabled;
          pointer-events: none;
          cursor: not-allowed;
        }

        > .loading > .loading__dot {
          background-color: $colorMedium;
        }
      }
    }

    @include colorOutlinedVariations(
      'green',
      $main-default,
      $main-dark,
      $main-super-dark,
      $main-light,
      $text-disabled
    );

    @include colorOutlinedVariations(
      'error',
      $error-default,
      $error-default,
      $error-default,
      $error-default,
      $text-disabled
    );
  }

  &--contained {
    padding: 12px 24px;

    @mixin colorContainedVariations(
      $name,
      $colorPrimary,
      $colorHover,
      $colorActive,
      $colorDisabled,
      $colorFont
    ) {
      &--#{$name} {
        background-color: $colorPrimary;
        color: $colorFont;

        &:hover {
          background-color: $colorHover;
          color: $colorFont;
        }

        &:active {
          background-color: $colorActive;
        }

        &:disabled {
          background-color: $colorDisabled;
          color: $colorFont;
          pointer-events: none;
          cursor: not-allowed;
        }

        > .loading > .loading__dot {
          background-color: $colorFont;
        }
      }
    }

    @include colorContainedVariations(
      'green',
      $main-default,
      $main-dark,
      $main-super-dark,
      $text-disabled,
      $black-900
    );
  }

  &--loading {
    color: transparent;
    pointer-events: none;
    div {
      display: flex;
    }
  }
}
