@import '../../../../styles/variables.scss';

.custom-player {
  background-color: $darkmode-dark;
  padding: 24px;

  &--content {
    background-color: $darkmode-light;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;

    & video {
      max-height: 180px;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div {
        display: flex;
        gap: 8px;
      }

      & button {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        background-color: $black-800;
        min-height: 25px;
        border: 1px solid transparent;
        border-radius: 4px;
        padding: 4px 8px;
        cursor: pointer;

        transition: 200ms;

        & span {
          display: none;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.4px;
          text-align: left;
        }

        &:hover {
          background-color: $darkmode-dark;
        }
      }
    }
  }

  & h3 {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;

  & button {
    border: none;
    background: none;
    cursor: pointer;
  }

  &--playback {
    display: flex;
    align-items: center;
    color: $black-300;
    font-size: 12px;
    font-weight: 700;
    position: absolute;
    right: 0;

    & span {
      min-width: 30px;
      text-align: center;
    }
  }
}

.progress-bar {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;

  & span {
    color: $black-400;
    font-size: 10px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.4px;
  }

  & input {
    /* === Mozilla specific styles === */
    &::-moz-range-thumb {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: $main-default;
      cursor: pointer;
      border: none;
    }

    &::-moz-range-progress {
      background-color: $main-super-dark;
      height: 4px;
    }
  }

  /* === range theme and appearance === */
  input[type='range'] {
    font-size: 1.5rem;
  }

  input[type='range'] {
    color: $main-dark;
    flex: 1;
    --thumb-height: 12px;
    --track-height: 4px;
    --track-color: rgba(0, 0, 0, 0.2);
    --brightness-hover: 130%;
    --brightness-down: 80%;
    --clip-edges: 0px;
  }

  /* === range commons === */
  input[type='range'] {
    position: relative;
    background: $darkmode-light;
    overflow: hidden;
  }

  input[type='range']:active {
    cursor: grabbing;
  }

  input[type='range']:disabled {
    filter: grayscale(1);
    opacity: 0.3;
    cursor: not-allowed;
  }

  /* === WebKit specific styles === */
  input[type='range'],
  input[type='range']::-webkit-slider-runnable-track,
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    // transition: all ease 100ms;
    height: var(--thumb-height);
  }

  input[type='range']::-webkit-slider-runnable-track,
  input[type='range']::-webkit-slider-thumb {
    position: relative;
  }

  input[type='range']::-webkit-slider-thumb {
    --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
    --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
    --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
    --clip-further: calc(100% + 1px);
    --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0 100vmax currentColor;

    width: var(--thumb-width, var(--thumb-height));
    background: linear-gradient(currentColor 0 0) scroll no-repeat left center / 50%
      calc(var(--track-height) + 1px);
    background-color: currentColor;
    box-shadow: var(--box-fill);
    border-radius: var(--thumb-width, var(--thumb-height));

    filter: brightness(100%);
    clip-path: polygon(
      100% -1px,
      var(--clip-edges) -1px,
      0 var(--clip-top),
      -100vmax var(--clip-top),
      -100vmax var(--clip-bottom),
      0 var(--clip-bottom),
      var(--clip-edges) 100%,
      var(--clip-further) var(--clip-further)
    );
  }

  input[type='range']:hover::-webkit-slider-thumb {
    filter: brightness(var(--brightness-hover));
    cursor: grab;
  }

  input[type='range']:active::-webkit-slider-thumb {
    filter: brightness(var(--brightness-down));
    cursor: grabbing;
  }

  input[type='range']::-webkit-slider-runnable-track {
    background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center / 100%
      calc(var(--track-height) + 1px);
  }

  input[type='range']:disabled::-webkit-slider-thumb {
    cursor: not-allowed;
  }
}

@media (min-width: $screen-md) {
  .custom-player {
    &--content {
      & video {
        max-height: 300px;
        max-width: 100%;
      }
    }
  }
}

@media (min-width: $screen-lg) {
  .custom-player {
    &--content {
      &-header {
        & button {
          & span {
            display: unset;
          }
        }
      }
    }
  }
}
