@import '../../styles/variables.scss';

.accordion-item {
  .tech-documentation__markdown__container {
    padding: 32px;
  }
}

.accordion-item-nested {
  padding: 0px 0 12px 32px;
}

.accordion-trigger {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  color: $white;
  font-weight: 400;
  width: 100%;
  text-align: left;
  padding: 40px 40px 12px 0;
  border: none;
  border-bottom: 1px solid $text-primary;
  background-color: transparent;
  cursor: pointer;

  & h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 120%;
    letter-spacing: 0.2px;
  }

  & p {
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.25px;
  }
}

.accordion-chevron {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.4s linear;
}

.accordion-content {
  animation: slideDown 0.4s linear;
  overflow: hidden;
}

.accordion-trigger[data-state='open'] {
  border-color: $main-dark;

  & > .accordion-chevron {
    transform: translateY(-50%) rotate(180deg);
  }
}

.accordion-content[data-state='closed'] {
  animation: slideUp 0.4s linear;
}

@keyframes slideDown {
  from {
    opacity: 0;
    height: 0;
  }
  to {
    opacity: 1;
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    height: var(--radix-accordion-content-height);
  }
  to {
    opacity: 0;
    height: 0;
  }
}
