.pdf__viewer {
  width: 100%;
  position: relative;

  & .spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.pdf__framer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1.375;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.react-pdf__Page.loading-page {
  display: none;
}
