@import '../../styles/variables.scss';

.document-item__value {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.25px;
  width: 100px;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  text-align: end;

  &--document-name {
    width: 150px;
  }

  &--with-icon {
    display: flex;
    gap: 8px;
    align-items: center;
  }
}

.document-item__value-tooltip {
  display: none;
  position: absolute;
  z-index: 2;
  color: $white;
  background-color: $darkmode-light;
  border: 1px solid;
  width: fit-content;
  max-width: 120px;
  padding: 4px;
  font-size: 12px;
  word-break: break-all;
  white-space: break-spaces;
  text-align: start;

  &--visible {
    display: block;
  }
}

@media screen and (min-width: $screen-sm) {
  .document-item__value {
    width: 180px;
  }
}

@media screen and (min-width: $screen-lg) {
  .document-item__value-tooltip {
    &--visible {
      display: none;
    }
  }
}
