@import '../../styles/variables.scss';

.payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 64px 24px;
  color: white;

  h1 {
    font-size: 32px;
    font-weight: 500;

    text-transform: capitalize;
  }

  h2 {
    margin-top: 36px;
    margin-bottom: 16px;
    align-self: left;
  }
}

.payment__card {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  flex-grow: 1;
}

.payment__container {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.payment__card__container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
}
