@import '../../styles/variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  padding: 16px 24px;
  box-sizing: border-box;
  border-bottom: 1px solid $text-primary;
  position: sticky;
  top: 0;
  background-color: $darkmode-super-dark;
  z-index: 10;

  nav {
    display: flex;
    gap: 16px;

    @media (min-width: $screen-lg) {
      gap: 32px;
    }
  }
}

.header__logo {
  display: flex;
  align-items: center;
  gap: 16px;

  button {
    background: transparent;
    border: none;
    padding: 0;
    align-items: center;
    display: flex;
    cursor: pointer;
  }

  p {
    margin-top: 1px;
    color: $main-hyper-dark;
    font-size: 10px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.4px;
    text-transform: uppercase;

    a {
      color: $white;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.4px;
    }
  }

  &--desktop {
    display: none;
  }

  @media (min-width: $screen-lg) {
    &--desktop {
      display: flex;
    }

    &--mobile {
      display: none;
    }
  }
}

.header__nav__dropdown {
  border: none;
  background: none;
  color: #ffffff;
  font-size: 0;
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0;
  text-transform: uppercase;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.header__nav__dropdown__icon {
  display: unset;
}

.header__user {
  display: flex;
  align-items: center;
  gap: 8px;

  &--desktop {
    display: none;
  }

  @media (min-width: $screen-lg) {
    &--desktop {
      display: flex;
    }

    &--mobile {
      display: none;
    }
  }
}

.header__user__data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 110px;
  gap: 0;
  overflow: hidden;

  p {
    width: 100%;
    display: block;
    color: $white;
    font-size: 12px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0.4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  span {
    color: $text-disabled;
    font-size: 10px;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: 0.4px;
  }
}

@media (min-width: $screen-lg) {
  .header__nav__dropdown {
    letter-spacing: 2px;
    font-size: 10px;
    gap: 8px;
  }

  .header__nav__dropdown__icon {
    display: none;
  }
}
