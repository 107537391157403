@import '../../styles/variables.scss';

.transcripts-list {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1216px;
  width: 100%;
  min-height: calc(100vh - 75px);
  margin: 0 auto;
  padding: 0px 32px 32px 32px;
  box-sizing: border-box;

  .progress__bar__container {
    align-items: center;
  }
}

.transcripts-list__info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  color: $white;
  gap: 16px;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  & > div > h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 120%;
  }

  & > div > p {
    font-size: 12px;
    font-weight: 400;
    line-height: 38.4px;
    letter-spacing: 0.4px;
    color: $white;
  }

  // & > button {
  //   display: flex;
  //   padding: 8px 16px;
  //   justify-content: center;
  //   align-items: center;
  //   gap: 8px;
  //   background-color: transparent;
  //   border: 1px solid $main-default;
  //   color: $white;
  //   font-size: 10px;
  //   font-weight: 700;
  //   letter-spacing: 2px;
  //   text-transform: uppercase;
  //   cursor: pointer;
  //   transition: filter 0.2s ease-in-out;

  //   &:hover {
  //     filter: brightness(0.8);
  //   }

  //   & > svg {
  //     width: 16px;
  //     height: 16px;
  //   }
  // }
}

.transcripts-list__divider {
  width: 100%;
  min-height: 1px;
  height: 1px;
  background-color: $text-primary;
}

.transcripts-list__refresh-info {
  display: flex;
  align-items: center;
  gap: 8px;
  opacity: 0;

  & > p {
    font-size: 14px;
    color: $main-default;
    white-space: nowrap;
  }

  &--visible {
    opacity: 1;
  }
}

.transcripts-list__listing-section {
  color: $white;

  & > div:first-child {
    display: flex;
    flex-direction: column;

    & > h6 {
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    & > p {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.4px;
    }
  }
}

.transcripts-list__listing-section__search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
  gap: 16px;
}

.transcripts-list__listing-section__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
  font-size: 14px;

  span {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & > h6 {
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    & > p {
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.4px;
      opacity: 0.8;
    }
  }
}

.transcripts-list__items {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 8px;

  &--loading {
    justify-content: center;
    align-items: center;
  }

  &--empty {
    align-items: flex-start;
    margin-top: 0px;
  }
}

.transcripts-list__empty-message {
  font-size: 14px;
  font-weight: 400;
  line-height: 38.4px;
  letter-spacing: 0.4px;
  color: $white;
}

@media screen and (min-width: $screen-lg) {
  .transcripts-list {
    padding: 55px 32px;
  }

  .transcripts-list__info {
    flex-direction: row;
    gap: 56px;
  }

  .transcripts-list__listing-section {
    & > div:first-child {
      flex-direction: row-reverse;
    }
  }

  .transcripts-list__listing-section__header {
    flex-direction: row;
    align-items: center;
    gap: 0px;
    justify-content: flex-end;
  }
}
