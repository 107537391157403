@import '../../styles/variables.scss';

.file-list {
  background-color: $darkmode-super-dark;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.file-list__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $white;

  & span {
    padding: 8px;
  }

  & > div {
    max-height: fit-content;
    display: flex;
    gap: 8px;
  }
}

.file-list__content {
  display: flex;
  gap: 10px;
  overflow-x: auto;
}

.file-card,
.file-card--highlight {
  background-color: $darkmode-light;
  border: 2px solid transparent;
  color: $white;
  display: flex;
  font-weight: 700;
  padding: 8px;

  & > button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    font-size: 10px;
    letter-spacing: 2px;
    text-transform: uppercase;
  }
}

.file-card--highlight {
  border-color: $main-default;
}

.file-card--ocr {
  box-shadow: inset 0 0 0 2px $info-default;
  background-color: transparent;
}
