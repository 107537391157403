@import '../../../styles/variables.scss';

.argument-card {
  background-color: $darkmode-dark;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 24px;
  text-align: justify;
  color: $white;
  position: relative;
  width: 100%;

  & h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &:hover {
    outline: 1px solid $main-default;
  }
}

.argument-card--dropdown__container {
  position: absolute;
  right: 4px;
  top: 12px;
}