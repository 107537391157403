@import '../../styles/variables.scss';

.chat {
  &__viewer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: $darkmode-super-light;
    padding: 16px;
    box-sizing: border-box;
    border-radius: 2px;
    height: fit-content;
    max-height: calc(100dvh - 200px);
    overflow-y: auto;
  }

  &__box {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 32px;
    background-color: $darkmode-dark;
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
    overflow-y: auto;
    max-height: calc(100dvh - 75px);
    &__header {
      display: flex;
      flex-direction: row;
      gap: 8px;
      justify-content: space-between;
      align-items: flex-start;

      &__content {
        display: flex;
        flex-direction: column;
        width: 70%;
        gap: 8px;

        h3 {
          width: 100%;
          color: $white;
          font-family: Roboto;
          font-size: 12px;
          font-weight: 500;
          line-height: 100%;
          letter-spacing: 2px;
          text-transform: uppercase;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        // h2 {
        //   color: $white;
        //   font-family: Roboto;
        //   font-size: 12px;
        //   font-weight: 500;
        //   line-height: 100%;
        //   letter-spacing: 2px;
        //   text-transform: uppercase;
        // }
      }

      &__text {
        align-items: stretch;
        color: $black-300;
        font-size: 12px;
        font-weight: 300;
        line-height: 150%;
        letter-spacing: 0.4px;
      }
    }
  }

  & * {
    &::-webkit-scrollbar {
      display: block;
      background: transparent;
      width: 16px;
    }

    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #b5b5b5;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
}

@media (min-width: $screen-lg) {
  .chat {
    &__box {
      width: 552px;
      max-width: calc(100vw - 64px);
    }
  }
}
