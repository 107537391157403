@import '../../styles/variables.scss';

.transcript {
  color: $white;
  margin: 55px auto;
  display: flex;
  flex-direction: column;
  width: 90vw;

  & h1 {
    font-size: 32px;
  }

  & h2 {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  & hr {
    border: none;
    height: 1px;
    background-color: $black-800;
    margin-block: 56px;
    width: 100%;
  }

  & * {
    &::-webkit-scrollbar {
      display: block;
      background: transparent;
      width: 14px;
    }

    &::-webkit-scrollbar-thumb {
      border: 5px solid transparent;
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #b5b5b5;
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }
  }
}

.transcript__container {
  display: flex;
  flex-direction: column;
}

.transcript__info {
  display: flex;
  gap: 16px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > p {
      font-size: 12px;
    }
  }
}

.transcript__section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.transcript__main {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.transcript__chat__container {
  display: flex;
}

.transcript__chat__section {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  gap: 32px;
}

.transcript__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 75px);
}

@media (min-width: $screen-xl) {
  .transcript {
    width: 85vw;
  }

  .transcript__main {
    flex-direction: row;
    gap: 32px;
  }

  .transcript__chat__section {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .transcript__section {
    width: calc(45vw - 16px);

    &--sticky {
      max-height: calc(100dvh - 87px);
      position: sticky;
      top: 80px;
      z-index: 1;
    }
  }
}
