@import '../../styles/variables.scss';

.favorite-prompts__trigger {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all ease 0.3s;
  background-color: transparent;
  border: 1px solid #64feda;
  width: fit-content;
  padding: 8px 16px;
  color: #64feda;

  & > span {
    font-size: 14px;
  }

  &--only-icon {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    aspect-ratio: 1/1;
    border-radius: 8px;
    padding: 0;
  }
}

.favorite-prompts__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px;
  box-sizing: border-box;
  width: 100%;
  max-width: 320px;

  & > h2 {
    font-size: 18px;
    color: $white;
  }
}

.favorite-prompts__list {
  display: flex;
  flex-direction: column;
  gap: 8px;

  // height: 240px;
  // overflow: auto;

  // &::-webkit-scrollbar {
  //   width: 10px;
  // }

  // &::-webkit-scrollbar-track {
  //   background-color: transparent;
  // }

  // &::-webkit-scrollbar-thumb {
  //   border: 3px solid rgba(0, 0, 0, 0);
  //   background-clip: padding-box;
  //   border-radius: 9999px;
  //   background-color: $black-400;
  // }
}

.favorite-prompts__item__prompt {
  display: flex;
  align-items: center;
  gap: 12px;
  // background-color: $text-primary;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;

  & > span {
    font-size: 14px;
    color: $white;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    flex: 1;
  }
}

.favorite-prompts__item__divider {
  width: 100%;
  height: 1px;
  background-color: $text-primary;
  margin-top: 8px;
}

.favorite-prompts__action-buttons {
  display: flex;
  gap: 8px;
  margin-left: auto;
  width: 72px;

  & > button {
    padding: 0px;

    & > svg {
      min-width: 16px;
      position: static;
    }
  }
}

.favorite-prompts__empty {
  color: $white;
}
