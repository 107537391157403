@import '../../../styles/variables.scss';

.tabs {
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.tabs__item {
  text-transform: uppercase;
  padding: 0 8px 16px;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $black-500;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;

  &--selected {
    color: $main-default;
  }

  &--disabled {
    color: $black-300;
    cursor: not-allowed;
  }
}

.tabs__choice {
  display: block;
  height: 2px;
  width: 100%;
  background-color: $main-default;
  position: absolute;
  bottom: 0px;
  pointer-events: none;
  transition: all 0.5s ease-in-out;
}