@import '../../styles/variables.scss';

@keyframes dynamicLoading {
  0% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  66% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.dynamic-loading--svg__container { 
  position: relative;
  padding: 12px;
  width: 100%;
  height: 180px;
}

@for $i from 1 through 3 {
  .dynamic-loading--svg-#{$i} {
    position: absolute;
    top: 12px;
    transform: translateX(-50%);
    animation: dynamicLoading 0.9s infinite forwards;
    animation-delay: #{$i * 0.3s};
  }
}

.dynamic-loading__container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(20px);
  background: rgba(0, 0, 0, 0.8);
  border: none;
  position: fixed;
  top: 75px;
  height: calc(100dvh - 75px);
  width: 100dvw;
  z-index: 10;
}

.dynamic-loading {
  box-sizing: border-box;
  background-color: $darkmode-dark;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: min(80dvw, 800px);

  & header {
    display: flex;
    gap: 12px;
    width: 100%;
    flex-direction: column;
    text-align: left;

    & h2 {
      font-size: 32px;
      font-weight: 700;
    }
    & p {
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;
      letter-spacing: 0.4px;
    }
  }

  & h3 {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 2px;
    color: $main-default;
    margin-bottom: 8px;
  }
}

.dynamic-loading--message {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.4px;
}

.dynamic-loading--hint-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
}

.dynamic-loading--hint-content {
  color: $black-300;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.4px;
  margin-bottom: 32px;
}