@import '../../styles/variables.scss';

.complaints-details {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-block: 48px 96px;
  margin-inline: auto;
  margin-bottom: 64px;
  width: min(740px, 90dvw);
  min-height: calc(100dvh - 75px);
  justify-content: center;
  align-items: center;

  & h1 {
    color: $white;
  }
}

.complaint-edit__container {
  & h3 {
    color: $white;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.4px;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 48px;
}

.complaint-content__container {
  color: $black-100;
  text-align: justify;
  margin-block: 32px;
}

.complaint-version__nav {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-top: 32px;
  width: 100%;
  max-width: 90dvw;

  & button {
    flex-shrink: 0;
  }
}
.complaint-version--list,
.complaint-version--actions {
  display: flex;
  gap: 12px;
  align-items: center;
  
  &::-webkit-scrollbar {
    display: block;
    background: transparent;
    width: 16px;
  }

  &::-webkit-scrollbar-thumb {
    border: 5px solid transparent;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #b5b5b5;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

.complaint-version--list {
  max-width: calc(100% - 132px);
  overflow-x: auto;
}

.arguments__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-block: 32px;
}

.add-argument--button {
  background-color: transparent;
  border: 2px dashed $main-default;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
  width: min(740px, 90dvw);
  padding: 48px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: $darkmode-dark;
  }
}
