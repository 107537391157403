@import '../../styles/variables.scss';

.general-configs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.general-configs__container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 80%;
  padding: 64px 0;
  gap: 48px;
  // gap: 64px;
}

.general-configs__title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 16px;

  h1 {
    color: $white;
    font-size: 32px;
    font-weight: 500;
    line-height: 38.4px;
  }

  span {
    color: $black-500;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0.4px;
  }
}

.line {
  width: 100%;
  height: 1px;
  // margin: 48px 0;
  background-color: $text-primary;
}

.general-configs__edit-container {
  width: 100%;
}

.general-configs__edit {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;

  h1 {
    color: $white;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }

  span {
    color: $black-500;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0.4px;
  }
}

.general-configs__inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 16px 0 0 0;
  gap: 16px;
}

.general-configs__inputs-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.general-configs__authentication-data-container {
  width: 100%;
}

.general-configs__authentication-data {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  width: 100%;
  gap: 16px;

  h1 {
    color: $white;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
}

.authentication-data__title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
}

.authentication-data__suggestion {
  color: $black-500;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.4px;
}

.authentication-data__buttons {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.authentication-data__inputs {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 16px 0 0 0;
  gap: 16px;
}

.general-configs__signature-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 10px 0px;
  border: 1px solid white;
  width: 100%;
  margin-top: 16px;

  .button {
    color: white;
    width: 150px;
  }
}

.general-configs__signature-container__price {
  display: flex;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 48px;

  span {
    font-size: 14px;
    font-weight: 400;
    &:nth-child(2) {
      font-size: 10px;
    }
  }
}

.general-configs__signature-container__features {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
  gap: 8px;
  margin-bottom: 10px;
  span {
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
      color: $main-default;
    }
  }
}

.general-configs__new-signature-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 16px;
}

.recaptcha__holder {
  iframe {
    height: 78px !important;
  }
}

@media (min-width: $screen-xl) {
  .general-configs__container {
    max-width: 1216px;
    // width: 1100px;
    // padding: 32px 0;
    gap: 48px;
  }

  .general-configs__title {
    width: 370px;
  }

  .general-configs__signature-container {
    flex-direction: row;
    justify-content: space-around;
  }

  .general-configs__signature-container__features {
    flex-direction: row;
    margin-bottom: 0px;
  }
}

@media (min-width: $screen-lg) {
  .general-configs__inputs {
    align-items: flex-start;
  }

  .general-configs__inputs-button {
    width: 190px;
  }

  .general-configs__authentication-data {
    flex-direction: row;
    align-items: center;
  }

  .authentication-data__buttons {
    min-width: 350px;
    width: 350px;
    flex-direction: row;
    gap: 12px;
  }
}
