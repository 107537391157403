@import '../../styles/variables.scss';

.invite {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  padding: 64px 16px;
  box-sizing: border-box;

  .animation-bg {
    position: fixed;

    .animation-bg__mouse {
      left: -175px;
    }

    .animation-bg__bubble {
      left: 40%;
    }

    .animation-bg__bubble2 {
      left: 40%;
    }

    .animation-bg__bubble2:nth-of-type(3) {
      left: 0;
    }
  }
}

.invite__form {
  z-index: 1;
  background-color: $black-900;
  padding: 32px;
  max-width: 500px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  animation: fadeInUp 1.5s ease;

  img {
    height: 25px;
    width: 90px;
    margin-bottom: 16px;
  }

  h1 {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;

    strong {
      color: #fff;
      font-size: 28px;
      font-weight: 500;
    }
  }

  p {
    color: $black-500;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    margin-bottom: 32px;
  }

  button {
    white-space: wrap;
    height: auto;
    min-height: 54px;
  }
}

.invite__form__row {
  display: grid;
  gap: 16px;
  grid-template-columns: auto;

  @media (min-width: $screen-lg) {
    grid-template-columns: 2fr 3fr;
  }
}

.invite__confirmation {
  z-index: 1;
  background-color: $black-900;
  padding: 32px;
  max-width: 500px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 32px;
  animation: fadeInUp 1.5s ease;

  img {
    height: 25px;
    width: 90px;
  }

  h1 {
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    max-width: 85%;

    strong {
      color: #fff;
      font-size: 28px;
      font-weight: 500;
    }
  }

  p {
    color: $black-500;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
}