@import '../../styles/variables.scss';

.alert__dialog__overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  inset: 0;
  animation: overlayShow 500ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 5;
}

.alert__dialog__content {
  pointer-events: auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: $black-900;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 500ms cubic-bezier(0.16, 1, 0.3, 1);
  z-index: 6;
}
.alert__dialog__content:focus {
  outline: none;
}

.alert__dialog__title {
  margin: 0;
  color: $white;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0.4px;
}

.alert__dialog__description {
  color: #959595;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.4px;
}

.alert__dialog__buttons__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.alert__dialog__button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
}
.alert__dialog__button.violet {
  background-color: white;
  color: var(--violet-11);
  box-shadow: 0 2px 10px var(--black-a7);
}
.alert__dialog__button.violet:hover {
  background-color: var(--mauve-3);
}
.alert__dialog__button.violet:focus {
  box-shadow: 0 0 0 2px black;
}
.alert__dialog__button.red {
  background-color: var(--red-4);
  color: var(--red-11);
}
.alert__dialog__button.red:hover {
  background-color: var(--red-5);
}
.alert__dialog__button.red:focus {
  box-shadow: 0 0 0 2px var(--red-7);
}
.alert__dialog__button.mauve {
  background-color: var(--mauve-4);
  color: var(--mauve-11);
}
.alert__dialog__button.mauve:hover {
  background-color: var(--mauve-5);
}
.alert__dialog__button.mauve:focus {
  box-shadow: 0 0 0 2px var(--mauve-7);
}

@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

@media (min-width: $screen-lg) {
  .alert__dialog__content {
    width: 90vw;
  }
  .alert__dialog__buttons__container {
    flex-direction: row;

    width: fit-content;
  }
}
