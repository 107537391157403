@import '../../styles/variables.scss';

.document-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $darkmode-dark;
  padding: 16px;
  box-sizing: border-box;

  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // gap: 16px;
    padding: 16px 0px;
    border-bottom: 1px solid $text-primary;

    &:last-child {
      border-bottom: none;
    }

    & > p:first-child {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0.25px;
    }
  }
}

.document-item__buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.document-item__pdf-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 41px;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  background-color: $darkmode-light;
  border: none;
}

@media screen and (min-width: $screen-lg) {
  .document-item {
    flex-direction: row;
    display: flex;
    padding: 16px 32px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .document-item__buttons {
      width: fit-content;
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 20px;
    }

    & > div {
      flex-direction: column;
      border-bottom: none;
      gap: 4px;
      align-items: flex-start;

      & > p:active:after {
        display: none;
      }

      & > p:last-child {
        text-align: start;
      }
    }

    & > div:first-child {
      flex-direction: row;
      align-items: center;
      gap: 16px;
    }
  }
}
