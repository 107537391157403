@import '../../styles/variables.scss';

.header-features {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}

.header-features__banner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  padding: 64px 32px;
  position: relative;
  background-color: #000000;

  & > h1 {
    font-size: 28px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.5px;
    text-align: center;
    color: $white;

    & > strong {
      font-weight: 700;
    }
  }

  & > p {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
    color: $white;
  }
}

.header-features__content {
  width: 100%;
  max-width: 1216px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 40px 32px;
}

.banner__unpaid-alert {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;
  padding: 16px 32px;
  width: 100%;
  background-color: $error-default;
  position: relative;
}

.banner__unpaid-alert__text {
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: calc(100% - 64px);

  & > svg {
    min-width: 24px;
    min-height: 24px;
  }

  & > span {
    color: $white;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    text-align: left;
  }
}

.banner__unpaid-alert__actions {
  display: flex;
  align-items: center;
  gap: 8px;

  & > button:first-child {
    background-color: transparent;
    border: 1px solid $error;
    box-sizing: border-box;
    padding: 8px 16px;
    gap: 8px;
    position: unset;
    top: unset;
    right: unset;
    cursor: pointer;

    & > span {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 700;
      line-height: 10px;
      letter-spacing: 2px;
      text-align: center;
    }

    transition: all 500ms;

    &:hover {
      background-color: rgba($color: $white, $alpha: 0.1);
    }
  }

  & > button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    width: fit-content;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 20px;
  }
}

@media (min-width: $screen-lg) {
  .header-features__banner {
    padding: 64px 112px;

    & > h1 {
      font-size: 48px;
    }

    & > p {
      font-size: 20px;
    }
  }

  .banner__unpaid-alert {
    flex-direction: row;
  }

  .banner__unpaid-alert__text {
    & > span {
      font-size: 16px;
    }
  }

  .banner__unpaid-alert__actions {
    & > button {
      position: unset;
      top: unset;
      right: unset;
    }
  }
}
