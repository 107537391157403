@import '../../styles/variables.scss';

.suggestion {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $main-default;
  border-radius: 2px;
  border: none;
  padding: 8px 16px;
  text-align: left;
  width: 100%;
  min-height: 70px;
  justify-content: center;

  cursor: pointer;

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $main-dark;
  }
}

.suggestion__title {
  color: $text-primary;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 4px;
}

.suggestion__description {
  color: $text-primary;
  font-size: 12px;
  line-height: 150%;
}
