@import '../../styles/variables.scss';

.chat {
  display: flex;
  flex-direction: column;
  max-width: 1216px;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  padding: 56px 32px;
  gap: 56px;
  box-sizing: border-box;

  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__section {
    width: 100%;
    margin-inline: auto;
    display: flex;
    min-height: calc(100dvh - 80px);
    justify-content: space-between;
    gap: 16px;
    position: relative;

    &__header {
      display: flex;
      gap: 8px;

      button {
        background-color: $black-800;
        font-size: 12px;
        padding: 4px 8px;
        color: $white;
        letter-spacing: 0.5px;
        font-weight: 400;
        height: auto;
      }
    }

    &--container {
      width: 100%;
      margin-inline: auto;
    }
  }
}

.line {
  width: 100%;
  height: 1px;
  background-color: $text-primary;
}
