@import '../../styles/variables.scss';

.dropdown__trigger {
  display: flex;
  align-items: center;
  gap: 8px;
  border: none;
  background: none;
  cursor: pointer;

  &:after {
    content: '';
    display: block;
    border: 4px solid $white;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    margin-top: 1px;
  }

  &--no-arrow {
    &:after {
      display: none;
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.dropdown__content {
  padding: 4px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  background: rgba(255, 255, 255, 0.25);
  z-index: 11;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);
}

.dropdown__content__item {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  color: $white;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0.25px;
  cursor: pointer;

  strong {
    font-weight: bold;
  }
}
