@import '../../styles/variables.scss';

.popover__content {
  padding: 4px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 5;
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12px);

  // height: 240px;
  // overflow: auto;
}
