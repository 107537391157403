@import '../../styles/variables.scss';

#credit-card__back {
  fill: #5ad9ac;
  display: none;
}
#credit-card__back__magnetic-strip {
  fill: #232323;
  display: none;
}
#credit-card__front {
  fill: #5ad9ac;
}
#credit-card__text__number,
#credit-card__text__name,
#credit-card__text__valid,
#credit-card__text__cvv {
  fill: #5ad9ac;
  font-weight: 700;
  font-size: 14px;
}
